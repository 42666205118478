import { useMutation, useQuery } from "@apollo/client"
import { DialogClose } from "@radix-ui/react-dialog"
import clsx from "clsx"
import { format, parse } from "date-fns"
import { Form, FormikContext, useFormik } from "formik"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useState } from "react"
import { DateRange } from "react-day-picker"
import toast from "react-hot-toast"
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom"
import invariant from "tiny-invariant"
import { getFragmentData, gql } from "~/__generated__"
import {
  DateFilter,
  NewsletterSizeBand,
  SortDirection,
  SponsorSearchInput,
  SponsorSortField,
} from "~/__generated__/graphql"
import { isBlank } from "~/common/is-blank"
import { salesProUrl, searchesPath } from "~/common/paths"
import { displayErrors } from "~/common/validation-errors"
import { GraphqlError } from "~/components/errors/graph-error"
import { FormikField } from "~/components/fields/formik-fields"
import { IndustryField } from "~/components/fields/industry-input"
import {
  NewsletterFieldWithSets,
  NewsletterOrSetOptions,
} from "~/components/fields/newsletter-input"
import { NewsletterSizeBandField } from "~/components/fields/newsletter-size-band-input"
import { SponsorField } from "~/components/fields/sponsor-input"
import { TopicField } from "~/components/fields/topic-input"
import DownloadSearch, {
  SearchDownloadFormat,
} from "~/components/misc/download-search"
import UpgradeBanner from "~/components/misc/upgrade-banner"
import { Button } from "~/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "~/components/ui/dialog"
import { Link } from "~/components/ui/link"
import { LoadingIndicatorCentered } from "~/components/ui/loading-indicator"
import { SelectItem } from "~/components/ui/select"
import { SponsorCard, SponsorCardGrid } from "~/components/ui/sponsor-card"
import { useDocumentTitle } from "~/hooks/use-document-title"
import { ArticlesSection } from "./articles-section"
import { GetWholePictureBanner } from "./get-whole-picture-banner"
import { TestimonialsSection } from "./testimonials-section"
import { UnauthorizedDownloadSearch } from "./unauthorized-download-search"
import { WhoSponsorsNewslettersBanner } from "./who-sponsors-newsletters-banner"

const sponsorQuery = gql(/* GraphQL */ `
  query LandingScreen(
    $filters: SponsorSearchInput
    $sponsorSearchId: ID
    $after: String
  ) {
    sponsors(
      first: 50
      after: $after
      filters: $filters
      sponsorSearchId: $sponsorSearchId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...SponsorCard
        }
      }
      totalCount
    }

    paidSponsorsCount(filters: $filters, sponsorSearchId: $sponsorSearchId)

    sponsorSearch: node(id: $sponsorSearchId) {
      ... on SponsorSearch {
        id
        ...SponsorSearchFilters
      }
    }

    canViewTestimonials {
      value
    }
    canViewWhoSponsorsNewslettersBanner {
      value
    }
    canViewGetWholePictureBanner {
      value
    }
  }
`)

const sponsorSearchFragment = gql(/* GraphQL */ `
  fragment SponsorSearchFilters on SponsorSearch {
    id

    sponsorshipCreatedRelative
    sponsorshipCreatedMax
    sponsorshipCreatedMin
    sponsorCreatedRelative
    sponsorCreatedMax
    sponsorCreatedMin
    industryIds
    name
    newsletterIds
    newsletterSetIds
    sponsorIds
    newsletterSizeBands
    search
    sortOrderDirection
    sortOrderField
    sponsorshipCountMin
    topicIds
    verified
  }
`)

const filterQuery = gql(/* GraphQL */ `
  query LandingScreenFilters {
    canFilterSponsors {
      value
    }
    canCreateSponsorSearch {
      value
    }
    canShowDownloadSponsorSearch {
      value
    }
  }
`)

const saveSearchMutation = gql(/* GraphQL */ `
  mutation SaveSearch($input: SponsorSearchCreateInput!) {
    sponsorSearchCreate(input: $input) {
      sponsorSearch {
        id
      }
    }
  }
`)

const downloadSearchMutation = gql(/* GraphQL */ `
  mutation SponsorSearchDownload($input: SponsorSearchDownloadInput!) {
    sponsorSearchDownload(input: $input) {
      success
    }
  }
`)

const defaultSortField = SponsorSortField.SponsorName
const defaultSortDirection = SortDirection.Asc

const DATE_FORMAT = "yyyy-MM-dd"

const parseDateRange = (min: string | null, max: string | null) => {
  return min && max
    ? ({
        from: parse(min, DATE_FORMAT, new Date()),
        to: parse(max, DATE_FORMAT, new Date()),
      } as DateRange)
    : min
    ? ({ from: parse(min, DATE_FORMAT, new Date()) } as DateRange)
    : null
}

const getMatchingRecordsCopy = (count = 0) => {
  return (
    <span>
      <b>{count.toLocaleString()}</b> Matching {pluralize("Record", count)}
    </span>
  )
}

export const SponsorsIndexScreen = () => {
  useDocumentTitle("Who Sponsors Stuff", "")

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const sponsorSearchId = searchParams.get("sponsorSearch")
  const [useSponsorSearchId, setUseSponsorSearchId] = useState(
    !!sponsorSearchId
  )
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false)
  const { showConferenceBanner } = useOutletContext<{
    showConferenceBanner: boolean
  }>()

  const filtersFormik = useFormik({
    initialValues: {
      search: searchParams.get("search") ?? "",
      topicIds: searchParams.getAll("topicId") ?? [],
      newsletterWithSets: {
        newsletterIds: searchParams.getAll("newsletterId") ?? [],
        newsletterSetIds: searchParams.getAll("newsletterSetId") ?? [],
      } as NewsletterOrSetOptions,
      sponsorIds: searchParams.getAll("sponsorId") ?? [],
      newsletterSearch: "",
      industryIds: searchParams.getAll("industryId") ?? [],
      sponsorshipCreatedRelative:
        searchParams.get("sponsorshipCreatedRelative") ?? DateFilter.All,
      sponsorshipCreatedRange: parseDateRange(
        searchParams.get("sponsorshipCreatedMin"),
        searchParams.get("sponsorshipCreatedMax")
      ),
      sponsorCreatedRelative:
        searchParams.get("sponsorCreatedRelative") ?? DateFilter.All,
      sponsorCreatedRange: parseDateRange(
        searchParams.get("sponsorCreatedMin"),
        searchParams.get("sponsorCreatedMax")
      ),
      newsletterSizeBands: searchParams.getAll("newsletterSizeBand") ?? [],
      minSponsorships: searchParams.get("minSponsorships") ?? "",
      sortField: searchParams.get("sortField") ?? defaultSortField,
      sortDirection: searchParams.get("sortDirection") ?? defaultSortDirection,
    },
    onSubmit: () => {},
  })

  const saveFormik = useFormik({
    initialValues: {
      name: "",
      emailsDaily: false,
      emailsWeekly: false,
      onlyNewSponsors: true,
    },
    onSubmit: (values) => {
      let input = {
        ...filters,
        ...values,
        emailsDaily: areEmailAlertsDisabled ? false : values.emailsDaily,
        emailsWeekly: areEmailAlertsDisabled ? false : values.emailsDaily,
      }

      return saveSearchExec({
        variables: {
          input: {
            sponsorSearchInput: input,
          },
        },
      })
    },
  })

  const enableSponsors = searchParams.has("enableSponsors")

  useEffect(() => {
    if (!filtersFormik.dirty) return
    if (useSponsorSearchId) {
      return
    }

    const newParams = new URLSearchParams()

    if (filtersFormik.values.search.trim().length > 0) {
      newParams.set("search", filtersFormik.values.search)
    }
    filtersFormik.values.topicIds.forEach((id) => {
      newParams.append("topicId", id)
    })
    filtersFormik.values.newsletterWithSets.newsletterIds.forEach((id) => {
      newParams.append("newsletterId", id)
    })
    filtersFormik.values.newsletterWithSets.newsletterSetIds.forEach((id) => {
      newParams.append("newsletterSetId", id)
    })
    filtersFormik.values.sponsorIds.forEach((id) => {
      newParams.append("sponsorId", id)
    })
    filtersFormik.values.industryIds.forEach((id) => {
      newParams.append("industryId", id)
    })

    if (filtersFormik.values.sponsorshipCreatedRelative !== DateFilter.All) {
      newParams.set(
        "sponsorshipCreatedRelative",
        filtersFormik.values.sponsorshipCreatedRelative
      )
    }

    if (filtersFormik.values.sponsorshipCreatedRelative === DateFilter.Custom) {
      if (filtersFormik.values.sponsorshipCreatedRange?.from) {
        newParams.set(
          "sponsorshipCreatedMin",
          format(filtersFormik.values.sponsorshipCreatedRange.from, DATE_FORMAT)
        )
      }

      if (filtersFormik.values.sponsorshipCreatedRange?.to) {
        newParams.set(
          "sponsorshipCreatedMax",
          format(filtersFormik.values.sponsorshipCreatedRange.to, DATE_FORMAT)
        )
      }
    } else {
      newParams.delete("sponsorshipCreatedMin")
      newParams.delete("sponsorshipCreatedMax")
    }

    if (filtersFormik.values.sponsorCreatedRelative !== DateFilter.All) {
      newParams.set(
        "sponsorCreatedRelative",
        filtersFormik.values.sponsorCreatedRelative
      )
    }

    if (filtersFormik.values.sponsorCreatedRelative === DateFilter.Custom) {
      if (filtersFormik.values.sponsorCreatedRange?.from) {
        newParams.set(
          "sponsorCreatedMin",
          format(filtersFormik.values.sponsorCreatedRange.from, DATE_FORMAT)
        )
      }

      if (filtersFormik.values.sponsorCreatedRange?.to) {
        newParams.set(
          "sponsorCreatedMax",
          format(filtersFormik.values.sponsorCreatedRange.to, DATE_FORMAT)
        )
      }
    } else {
      newParams.delete("sponsorCreatedMin")
      newParams.delete("sponsorCreatedMax")
    }

    filtersFormik.values.newsletterSizeBands.forEach((id) => {
      newParams.append("newsletterSizeBand", id)
    })

    if (!isBlank(filtersFormik.values.minSponsorships)) {
      newParams.set("minSponsorships", filtersFormik.values.minSponsorships)
    }

    if (filtersFormik.values.sortField !== defaultSortField) {
      newParams.set("sortField", filtersFormik.values.sortField)
    }

    if (filtersFormik.values.sortDirection !== defaultSortDirection) {
      newParams.set("sortDirection", filtersFormik.values.sortDirection)
    }

    if (enableSponsors) {
      newParams.set("enableSponsors", "true")
    }

    setSearchParams(newParams, { preventScrollReset: true, replace: true })
    setShowUpgradeBanner(true)
  }, [
    filtersFormik.dirty,
    filtersFormik.values,
    setSearchParams,
    useSponsorSearchId,
    setShowUpgradeBanner,
    enableSponsors,
  ])

  const [sponsorshipCreatedMin, sponsorshipCreatedMax] = useMemo(() => {
    if (filtersFormik.values.sponsorshipCreatedRelative === DateFilter.Custom)
      if (
        filtersFormik.values.sponsorshipCreatedRange?.from &&
        filtersFormik.values.sponsorshipCreatedRange?.to
      ) {
        return [
          filtersFormik.values.sponsorshipCreatedRange.from,
          filtersFormik.values.sponsorshipCreatedRange.to,
        ]
      } else if (filtersFormik.values.sponsorshipCreatedRange?.from) {
        return [filtersFormik.values.sponsorshipCreatedRange.from, null]
      }

    return [null, null]
  }, [
    filtersFormik.values.sponsorshipCreatedRelative,
    filtersFormik.values.sponsorshipCreatedRange,
  ])

  const [sponsorCreatedMin, sponsorCreatedMax] = useMemo(() => {
    if (filtersFormik.values.sponsorCreatedRelative === DateFilter.Custom)
      if (
        filtersFormik.values.sponsorCreatedRange?.from &&
        filtersFormik.values.sponsorCreatedRange?.to
      ) {
        return [
          filtersFormik.values.sponsorCreatedRange.from,
          filtersFormik.values.sponsorCreatedRange.to,
        ]
      } else if (filtersFormik.values.sponsorCreatedRange?.from) {
        return [filtersFormik.values.sponsorCreatedRange.from, null]
      }

    return [null, null]
  }, [
    filtersFormik.values.sponsorCreatedRelative,
    filtersFormik.values.sponsorCreatedRange,
  ])

  const filters: SponsorSearchInput = {
    search: filtersFormik.values.search,
    topicIds: filtersFormik.values.topicIds,
    newsletterIds: filtersFormik.values.newsletterWithSets.newsletterIds,
    newsletterSetIds: filtersFormik.values.newsletterWithSets.newsletterSetIds,
    sponsorIds: filtersFormik.values.sponsorIds,
    industryIds: filtersFormik.values.industryIds,
    sponsorshipCreatedRelative: filtersFormik.values
      .sponsorshipCreatedRelative as DateFilter,
    sponsorshipCreatedMin,
    sponsorshipCreatedMax,
    sponsorCreatedRelative: filtersFormik.values
      .sponsorCreatedRelative as DateFilter,
    sponsorCreatedMin,
    sponsorCreatedMax,
    newsletterSizeBands: filtersFormik.values
      .newsletterSizeBands as NewsletterSizeBand[],
    sponsorshipCountMin: isBlank(filtersFormik.values.minSponsorships)
      ? null
      : parseInt(filtersFormik.values.minSponsorships, 10),
    sortBy: {
      field: filtersFormik.values.sortField as SponsorSortField,
      direction: {
        [SponsorSortField.SponsorName]: SortDirection.Asc,
        [SponsorSortField.LatestSponsorshipPlacementDate]: SortDirection.Desc,
        [SponsorSortField.CreatedAt]: SortDirection.Desc,
        [SponsorSortField.HighestSpending]: SortDirection.Desc,
      }[filtersFormik.values.sortField as SponsorSortField],
    },
  }

  const hasSelectedCustomSponsorshipCreated =
    filters.sponsorshipCreatedRelative === DateFilter.Custom &&
    filtersFormik.values.sponsorshipCreatedRange !== null
  const hasSelectedCustomSponsorCreated =
    filters.sponsorCreatedRelative === DateFilter.Custom &&
    filtersFormik.values.sponsorCreatedRange !== null
  const areEmailAlertsDisabled =
    hasSelectedCustomSponsorshipCreated || hasSelectedCustomSponsorCreated

  const areFiltersSet = useMemo(() => {
    return (
      filtersFormik.values.search.trim().length > 0 ||
      filtersFormik.values.topicIds.length > 0 ||
      filtersFormik.values.newsletterWithSets.newsletterIds.length > 0 ||
      filtersFormik.values.newsletterWithSets.newsletterSetIds.length > 0 ||
      filtersFormik.values.sponsorIds.length > 0 ||
      filtersFormik.values.industryIds.length > 0 ||
      filtersFormik.values.sponsorshipCreatedRelative !== DateFilter.All ||
      filtersFormik.values.sponsorshipCreatedRange ||
      filtersFormik.values.sponsorCreatedRelative !== DateFilter.All ||
      filtersFormik.values.sponsorCreatedRange ||
      filtersFormik.values.newsletterSizeBands.length > 0 ||
      !isBlank(filtersFormik.values.minSponsorships) ||
      filtersFormik.values.sortField !== defaultSortField ||
      filtersFormik.values.sortDirection !== defaultSortDirection
    )
  }, [filtersFormik.values])

  const resetFilters = useCallback(() => {
    filtersFormik.setValues({
      search: "",
      topicIds: [],
      newsletterWithSets: { newsletterIds: [], newsletterSetIds: [] },
      sponsorIds: [],
      newsletterSearch: "",
      industryIds: [],
      sponsorshipCreatedRelative: DateFilter.All,
      sponsorshipCreatedRange: null,
      sponsorCreatedRelative: DateFilter.All,
      sponsorCreatedRange: null,
      newsletterSizeBands: [],
      minSponsorships: "",
      sortField: defaultSortField,
      sortDirection: defaultSortDirection,
    })
  }, [filtersFormik])

  const submitFilters = {
    ...filters,
    verified: true,
  }

  const result = useQuery(sponsorQuery, {
    variables: {
      after: null,
      ...(useSponsorSearchId
        ? { sponsorSearchId }
        : { filters: submitFilters }),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.sponsorSearch) {
        invariant(data.sponsorSearch.__typename === "SponsorSearch")
        const sponsorSearch = getFragmentData(
          sponsorSearchFragment,
          data.sponsorSearch
        )

        filtersFormik.setValues({
          search: sponsorSearch.search ?? "",
          topicIds: sponsorSearch.topicIds,
          newsletterWithSets: {
            newsletterIds: sponsorSearch.newsletterIds,
            newsletterSetIds: sponsorSearch.newsletterSetIds,
          },
          sponsorIds: sponsorSearch.sponsorIds,
          newsletterSearch: "",
          industryIds: sponsorSearch.industryIds,
          sponsorshipCreatedRelative: sponsorSearch.sponsorshipCreatedRelative,
          sponsorshipCreatedRange: parseDateRange(
            sponsorSearch.sponsorshipCreatedMin,
            sponsorSearch.sponsorshipCreatedMax
          ),
          sponsorCreatedRelative: sponsorSearch.sponsorCreatedRelative,
          sponsorCreatedRange: parseDateRange(
            sponsorSearch.sponsorCreatedMin,
            sponsorSearch.sponsorCreatedMax
          ),
          newsletterSizeBands: sponsorSearch.newsletterSizeBands,
          minSponsorships: sponsorSearch.sponsorshipCountMin?.toString() ?? "",
          sortField: sponsorSearch.sortOrderField ?? defaultSortField,
          sortDirection:
            sponsorSearch.sortOrderDirection ?? defaultSortDirection,
        })
        setUseSponsorSearchId(false)
      }
    },
  })

  const [saveSearchExec, saveSearchResult] = useMutation(saveSearchMutation, {
    onCompleted: () => {
      saveFormik.setFieldValue("name", "")
      setSaveDialogOpen(false)
      navigate(searchesPath({}))
      toast.success("Search saved")
    },
    onError: (error) => {
      displayErrors(error.graphQLErrors, saveFormik.setFieldError)
    },
  })

  const [downloadSearchExec, downloadSearchResult] = useMutation(
    downloadSearchMutation,
    {
      onCompleted: () => {
        toast.success("Download is processing and will be sent to your email", {
          duration: 2500,
        })
      },
      onError: () => {
        toast.error("Download failed, please try again")
      },
    }
  )

  const filterResult = useQuery(filterQuery, {
    variables: {},
  })

  const filterData = filterResult.data ?? filterResult.previousData
  const sponsorCount = result.data?.sponsors.totalCount
  const paidSponsorCount = result.data?.paidSponsorsCount

  const data = result.data ?? result.previousData
  if (data == null && result.loading) {
    return (
      <div className="flex flex-col gap-6 md:gap-10 p-4 md:p-10 max-w-page mx-auto">
        <h1 className="text-4xl font-bold">Find Your Next Sponsor</h1>
        <div className="grid md:grid-cols-sponsor-index gap-4">
          <div>
            <div className="flex flex-col gap-2 p-6 mx-auto bg-gray-100 w-full rounded pb-6 bg-secondary text-secondary-foreground">
              <h3 className="text-xl font-bold">Filters</h3>
            </div>
          </div>
          <div className="min-h-screen">
            <LoadingIndicatorCentered />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="flex flex-col gap-6 md:gap-10 p-4 md:p-10 max-w-page mx-auto">
        <div
          className={clsx(
            "flex flex-col gap-4",
            "md:flex-row md:justify-between md:items-center"
          )}
        >
          <h1 className="text-4xl font-bold">Find Your Next Sponsor</h1>
          {!result.loading && (
            <div className={clsx("flex items-center", "md:text-right")}>
              {paidSponsorCount === null ? (
                <div>{getMatchingRecordsCopy(sponsorCount)}</div>
              ) : (
                <div className="flex flex-col gap-1">
                  <div>
                    {getMatchingRecordsCopy(sponsorCount)} for Free Users
                  </div>
                  <div>
                    {getMatchingRecordsCopy(paidSponsorCount)} for{" "}
                    <Link
                      to={salesProUrl}
                      className="font-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Paid Users
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="grid md:grid-cols-sponsor-index gap-4">
          <div>
            <div className="flex flex-col gap-4 p-6 mx-auto bg-gray-100 w-full rounded pb-6 bg-secondary text-secondary-foreground">
              <div className="flex gap-2">
                <h3 className="text-xl font-bold">Filters</h3>
                {result.loading && (
                  <div className="ms-auto">
                    <LoadingIndicatorCentered />
                  </div>
                )}
                {areFiltersSet && !result.loading ? (
                  <button
                    type="button"
                    className="btn btn-primary ms-auto underline"
                    onClick={() => {
                      resetFilters()
                    }}
                  >
                    Clear All Filters
                  </button>
                ) : null}
              </div>
              {filterResult.error ? (
                <GraphqlError error={filterResult.error} />
              ) : null}
              <FormikContext.Provider value={filtersFormik}>
                <Form>
                  <div className="grid grid-cols-1 gap-2 w-full mb-3">
                    {filterData?.canFilterSponsors.value ? (
                      <>
                        <FormikField
                          name="search"
                          type="search"
                          label="Search sponsors"
                          placehoder="Sponsor name…"
                          onClear={() => {
                            filtersFormik.setFieldValue("search", "")
                          }}
                          tooltip="Search sponsors by name "
                        />
                        <IndustryField
                          name="industryIds"
                          label="Sponsor industries"
                        />

                        <FormikField
                          name="sponsorCreatedRelative"
                          label="Sponsor added"
                          as="select"
                          isDirty={
                            filtersFormik.values.sponsorCreatedRelative !==
                            DateFilter.All
                          }
                          onClear={() => {
                            filtersFormik.setFieldValue(
                              "sponsorCreatedRelative",
                              DateFilter.All
                            )
                            filtersFormik.setFieldValue(
                              "sponsorCreatedRange",
                              null
                            )
                          }}
                          tooltip="Filter sponsors by how recently they've been added to the database"
                        >
                          <SelectItem value={DateFilter.All}>All</SelectItem>
                          <SelectItem value={DateFilter.Last_24H}>
                            Past Day
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_7D}>
                            Past 7 Days
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_30D}>
                            Past 30 Days
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_1Y}>
                            Past Year
                          </SelectItem>
                          <SelectItem value={DateFilter.Custom}>
                            Choose dates…
                          </SelectItem>
                        </FormikField>

                        {filtersFormik.values.sponsorCreatedRelative ===
                        DateFilter.Custom ? (
                          <FormikField
                            name="sponsorCreatedRange"
                            label="Sponsor date range"
                            as="date-range"
                          />
                        ) : null}

                        <NewsletterFieldWithSets
                          name="newsletterWithSets"
                          label="Newsletters"
                          disabled={filtersFormik.values.topicIds.length > 0}
                          canCreateNewsletterSets={
                            filterData.canCreateSponsorSearch.value
                          }
                          tooltip="Filter sponsors by newsletters where they've appeared"
                        />
                        {searchParams.has("enableSponsors") ||
                        filtersFormik.values.sponsorIds.length > 0 ? (
                          <SponsorField name="sponsorIds" label="Sponsors" />
                        ) : null}

                        <TopicField
                          name="topicIds"
                          label="Newsletter topics"
                          disabled={
                            filtersFormik.values.newsletterWithSets
                              .newsletterIds.length > 0 ||
                            filtersFormik.values.newsletterWithSets
                              .newsletterSetIds.length > 0
                          }
                          tooltip="Filter sponsors by the topics of newsletters where they've appeared"
                        />

                        <NewsletterSizeBandField
                          name="newsletterSizeBands"
                          label="Newsletter size"
                          tooltip="Filter sponsors by the sizes of newsletters where they've appeared"
                        />

                        <FormikField
                          name="sponsorshipCreatedRelative"
                          label="Ad run date"
                          as="select"
                          isDirty={
                            filtersFormik.values.sponsorshipCreatedRelative !==
                            DateFilter.All
                          }
                          onClear={() => {
                            filtersFormik.setFieldValue(
                              "sponsorshipCreatedRelative",
                              DateFilter.All
                            )
                            filtersFormik.setFieldValue(
                              "sponsorshipCreatedRange",
                              null
                            )
                          }}
                          tooltip="Filter sponsors by when they've run ads"
                        >
                          <SelectItem value={DateFilter.All}>All</SelectItem>
                          <SelectItem value={DateFilter.Last_24H}>
                            Past Day
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_7D}>
                            Past 7 Days
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_30D}>
                            Past 30 Days
                          </SelectItem>
                          <SelectItem value={DateFilter.Last_1Y}>
                            Past Year
                          </SelectItem>
                          <SelectItem value={DateFilter.Custom}>
                            Choose dates…
                          </SelectItem>
                        </FormikField>

                        {filtersFormik.values.sponsorshipCreatedRelative ===
                        DateFilter.Custom ? (
                          <FormikField
                            name="sponsorshipCreatedRange"
                            label="Ad Date Range"
                            as="date-range"
                          />
                        ) : null}

                        <FormikField
                          name="minSponsorships"
                          type="number"
                          label="Ad frequency"
                          step={1}
                          isDirty={filtersFormik.values.minSponsorships !== ""}
                          onClear={() => {
                            filtersFormik.setFieldValue("minSponsorships", "")
                          }}
                          tooltip="Filter sponsors by how many ads they've run"
                        />
                        <FormikField
                          name="sortField"
                          label="Sort"
                          as="select"
                          isDirty={
                            filtersFormik.values.sortField !==
                            SponsorSortField.SponsorName
                          }
                          onClear={() => {
                            filtersFormik.setFieldValue(
                              "sortField",
                              SponsorSortField.SponsorName
                            )
                          }}
                        >
                          <SelectItem value={SponsorSortField.CreatedAt}>
                            Most recent (added to database)
                          </SelectItem>
                          <SelectItem
                            value={
                              SponsorSortField.LatestSponsorshipPlacementDate
                            }
                          >
                            Most recent (ad run)
                          </SelectItem>
                          <SelectItem value={SponsorSortField.HighestSpending}>
                            Highest spending
                          </SelectItem>
                          <SelectItem value={SponsorSortField.SponsorName}>
                            Alphabetical
                          </SelectItem>
                        </FormikField>
                      </>
                    ) : null}
                  </div>
                </Form>
              </FormikContext.Provider>

              {filterData?.canCreateSponsorSearch.value ? (
                <>
                  <Dialog
                    open={saveDialogOpen}
                    onOpenChange={setSaveDialogOpen}
                  >
                    <DialogTrigger asChild>
                      <Button type="button" variant="green">
                        Save Search
                      </Button>
                    </DialogTrigger>

                    <DialogContent>
                      <DialogHeader>Save search</DialogHeader>
                      <FormikContext.Provider value={saveFormik}>
                        <Form className="flex flex-1 flex-col gap-4">
                          <div className="mt-4">
                            <FormikField
                              name="name"
                              placeholder="Enter search title"
                            />
                          </div>

                          <div className="text-md font-semibold mt-6">
                            Email Alerts
                          </div>

                          <div
                            className={clsx(
                              "max-w-[300px] pl-4 flex flex-col gap-2",
                              {
                                "opacity-50": areEmailAlertsDisabled,
                              }
                            )}
                          >
                            <FormikField
                              as="switch"
                              name="emailsDaily"
                              label="Daily Alerts"
                              showCheckedLabel={true}
                              containerClassName="flex flex-1 items-center gap-4 justify-between w-full"
                              row={true}
                              color="green"
                              disabled={areEmailAlertsDisabled}
                            />
                            <FormikField
                              as="switch"
                              name="emailsWeekly"
                              label="Weekly Alerts"
                              showCheckedLabel={true}
                              containerClassName="flex flex-1 items-center gap-4 justify-between w-full"
                              row={true}
                              color="green"
                              disabled={areEmailAlertsDisabled}
                            />
                            <div className="mt-2 mb-5">
                              <FormikField
                                type="checkbox"
                                name="onlyNewSponsors"
                                label="Only New Sponsors"
                                disabled={areEmailAlertsDisabled}
                                tooltip="If checked, only sponsors newly added to the database will appear in your email alerts"
                              />
                            </div>
                          </div>

                          <div className="flex gap-3 flex-row-reverse pt-4 -mb-4 -mx-8 px-8 border-t border-input">
                            <Button
                              type="submit"
                              variant="secondary"
                              isLoading={saveSearchResult.loading}
                              disabled={saveSearchResult.loading}
                            >
                              Save
                            </Button>
                            <DialogClose asChild>
                              <Button type="reset" variant="secondaryOutline">
                                Back
                              </Button>
                            </DialogClose>
                          </div>
                          <GraphqlError error={saveSearchResult.error} />
                        </Form>
                      </FormikContext.Provider>
                    </DialogContent>
                  </Dialog>
                  {filterData?.canShowDownloadSponsorSearch.value ? (
                    <DownloadSearch
                      disabled={downloadSearchResult.loading}
                      downloadSearch={(format: SearchDownloadFormat) => {
                        downloadSearchExec({
                          variables: {
                            input: {
                              sponsorSearchInput: submitFilters,
                              format,
                            },
                          },
                        })
                      }}
                    />
                  ) : (
                    <UnauthorizedDownloadSearch />
                  )}
                </>
              ) : null}
            </div>
          </div>
          <div className="flex">
            <div className="flex gap-4 w-full flex-col lg:flex-row">
              <div className="flex flex-col gap-4 w-full relative">
                <GraphqlError error={result.error} />
                {data && data.sponsors.edges.length === 0 ? (
                  <div className="text-center">
                    No Matching Sponsors found. Try changing your search
                    critiera.
                  </div>
                ) : null}
                <SponsorCardGrid>
                  {data &&
                    data.sponsors.edges
                      .map((e) => e.node)
                      .map((sponsor) => (
                        <SponsorCard key={sponsor.id} sponsor={sponsor} />
                      ))}
                </SponsorCardGrid>

                {data && data.sponsors.pageInfo.hasNextPage && (
                  <div>
                    <Button
                      type="button"
                      onClick={() => {
                        invariant(data)
                        result.fetchMore({
                          variables: {
                            after: data.sponsors.pageInfo.endCursor,
                          },
                        })
                      }}
                      disabled={result.loading}
                      className="flex gap-2"
                      isLoading={result.loading}
                    >
                      See more
                    </Button>
                  </div>
                )}
                {showUpgradeBanner && !showConferenceBanner && (
                  <UpgradeBanner onClose={() => setShowUpgradeBanner(false)} />
                )}
              </div>
            </div>
          </div>
        </div>
        {data?.canViewTestimonials?.value && <TestimonialsSection />}
        <ArticlesSection />
      </div>
      {data?.canViewWhoSponsorsNewslettersBanner?.value && (
        <WhoSponsorsNewslettersBanner />
      )}
      {data?.canViewGetWholePictureBanner?.value && <GetWholePictureBanner />}
    </>
  )
}
