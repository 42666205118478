import { Outlet, ScrollRestoration } from "react-router-dom"
import { useState, useEffect } from "react"

import { Footer } from "~/components/ui/footer"
import { Header } from "~/components/ui/header"
import { ConferenceBanner } from "~/components/ui/conference-banner"

const BANNER_DISMISSED_KEY = "conference-banner-dismissed-until"

export const LayoutScreen = () => {
  const [showConferenceBanner, setShowConferenceBanner] = useState(false)

  useEffect(() => {
    const dismissedUntil = localStorage.getItem(BANNER_DISMISSED_KEY)
    if (!dismissedUntil || new Date(dismissedUntil) < new Date()) {
      setShowConferenceBanner(true)
    }
  }, [])

  const handleDismiss = () => {
    const threeWeeksFromNow = new Date()
    threeWeeksFromNow.setDate(threeWeeksFromNow.getDate() + 21)
    localStorage.setItem(BANNER_DISMISSED_KEY, threeWeeksFromNow.toISOString())
    setShowConferenceBanner(false)
  }
  return (
    <div className="flex flex-col flex-1 break-words">
      <ScrollRestoration />
      <Header />
      <div className="flex-grow min-h-[500px]">
        <Outlet context={{ showConferenceBanner }} />
      </div>
      <Footer />
      {showConferenceBanner && <ConferenceBanner onClose={handleDismiss} />}
    </div>
  )
}
