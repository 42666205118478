import { XIcon } from "lucide-react"
import { Button } from "./button"
import { Card, CardContent } from "./card"
import conferenceImage from "~/images/the-newsletter-conference"

export const ConferenceBanner = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="sticky z-20 text-center bottom-0 mt-3">
      <Card className="mx-auto max-w-3xl mb-10 bg-black text-white border-none relative opacity-95">
        <CardContent className="flex flex-col py-4 px-6 pr-10 items-center">
          <img
            src={conferenceImage.src}
            srcSet={conferenceImage.srcSet}
            width={conferenceImage.width}
            height={conferenceImage.height}
            alt="The Newsletter Conference"
            className="mb-4"
          />
          <div className="text-center max-w-lg mb-4">
            Join us at The Newsletter Conference, May 2, 2025, in New York City.
            Get your tickets here!
          </div>
          <Button
            className="w-full md:w-auto"
            style={{ backgroundColor: "#E69037" }}
            onClick={() =>
              window.open(
                "https://thenewsletterconference.com/?utm_source=wss&utm_medium=web&utm_campaign=popup",
                "_blank"
              )
            }
          >
            Get Tickets
          </Button>
          <div className="absolute right-1 top-1">
            <Button className="p-1" variant="ghost" onClick={onClose}>
              <XIcon className="text-white" />
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
